import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import VueMeta from "vue-meta";
Vue.use(VueMeta);
// import VConsole from "vconsole";
// const vConsole = new VConsole();

import "./assets/css/public.scss";

import request from "./utils/request";
import vv from "./utils/request-vv";
import api from "./utils/api";
Vue.prototype.$request = request;
Vue.prototype.$vv = vv;
Vue.prototype.$api = api;

import moment from "moment";
moment.locale("zh-cn");
Vue.prototype.$moment = moment;

import dayjs from "dayjs";
Vue.prototype.$dayjs = dayjs;

Vue.config.productionTip = false;

// 日期格式
Vue.filter("formatDate", (str) => {
  return str.replace(/-/g, "/");
});
Vue.filter("formatTime", (str) => {
  return str.substring(0, 16);
});

Vue.filter("formatPosition", (str) => {
  let res = "";
  let pos =
    JSON.parse(localStorage.getItem("enums"))["EPersonnelPosition"] || [];
  pos.forEach((item) => {
    if (item.name === str) {
      res = item.label;
    }
  });
  return res;
});
Vue.filter("formatVehiclePosition", (str) => {
  let res = "";
  let pos = JSON.parse(localStorage.getItem("enums"))["EVehiclePosition"] || [];
  pos.forEach((item) => {
    if (item.name === str) {
      res = item.label;
    }
  });
  return res;
});
Vue.filter("formatPhone", (str) => {
  return str ? str.substr(0, 3) + "****" + str.substr(7) : '';
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
