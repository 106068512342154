<template>
  <div class="home custom-bg" v-if="details">
    <div class="header">
      <div>{{ details.name }}</div>
      <div>
        {{ details.startTime | formatDate }} -
        {{ details.endTime | formatDate }}
      </div>
    </div>

    <van-swipe class="my-swipe" :autoplay="5000" :show-indicators="false">
      <template v-for="(item, index) in details.attachViews">
        <van-swipe-item :key="index" @click="prev(index)"
          ><img :src="resourceUrl + item.path" alt=""
        /></van-swipe-item>
      </template>
    </van-swipe>

    <div class="custom-container">
      <div class="content-line">
        <div>举办时间</div>
        <div>
          {{ details.startTime | formatDate }} -
          {{ details.endTime | formatDate }}
        </div>
      </div>
      <div class="content-line">
        <div>组织时间</div>
        <div>
          {{ details.organizationStartTime | formatDate }} -
          {{ details.organizationEndTime | formatDate }}
        </div>
      </div>
      <div class="content-line">
        <div>活动地点</div>
        <div>{{ details.address }}</div>
      </div>
    </div>

    <div class="custom-container">
      <div class="caption-title">活动介绍</div>
      <div class="details" v-html="content"></div>
    </div>

    <div class="zw"></div>

    <div class="bottom-nav">
      <!-- <div @click="openUrl('https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8af826931365ee0&redirect_uri=https%3A%2F%2Fvip.vv-che.com%2Fregister%23%2FinfoAdd&response_type=code&scope=snsapi_base&state=infoAdd#wechat_redirect')">参与登记</div>
      <div class="active" @click="goServer" >活动服务</div> -->
      <div class="active" @click="goPath">进入活动</div>
    </div>
  </div>
</template>

<script>
import { ImagePreview } from "vant";
export default {
  data() {
    return {
      id: "",
      details: null,
      content: "",
      resourceUrl: this.$api.resourceUrl,
      imgList: [],
      isWechat: false,
      code: '',
      bindInfo: null,
      isAutoLogin: false,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getData();
    }

    if(this.$route.query.code) {
      this.code = this.$route.query.code
      this.checkUserBind()
    } else {
      this.isWechat = this.checkWechat()
      if(this.isWechat) {
        let currentUrl = 'https://org-activity.vv-che.com/?id=' + this.id
        let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8af826931365ee0&redirect_uri=${currentUrl}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
        window.open(url);
      }
    }
  },
  methods: {
    openUrl(url) {
      window.open(url)
    },
    goServer() {
      
      if(this.details.entranceId) {
        let url = '/travel_order/index?orderEntryId='
        window.open(`//${this.$api.vvcom}${url}${this.details.entranceId}`)
      }else {
        this.$toast('发布单位未提供活动服务')
      }
    },
    // 判断是否为微信浏览器
    checkWechat() {
      return String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) === "micromessenger";
    },
    // 检查微信用户是否绑定公众号，并返回用户信息
    checkUserBind() {
      this.$vv({
        url: this.$api.getCode,
        params: {
          code: this.code
        },
      }).then((res) => {
        this.bindInfo = res.data || null

        if(this.bindInfo) {
          this.getToken(res.data.mobilePhone)
          this.getVVToken(res.data.wxOpenid)
        }
      });
    },
    // 获取token
    getToken(mobile) {
      this.$request({
        url: this.$api.autologin,
        method: 'POST',
        data: {
          activityId: this.id,
          mobile: mobile
        },
      }).then((res) => {
        if(res.item) {
          localStorage.setItem("token", res.item.token);
          localStorage.setItem("companyId", res.item.companyId);
          // localStorage.setItem(
          //   "userInfo",
          //   JSON.stringify(res.item.userInfo)
          // );
        }
      });
    },
    // 获取token
    getVVToken(openid) {
      this.$vv({
        url: this.$api.vvToken,
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          loginType: 0,
          whichEnd: 0,
          wxOpenid: openid
        }
      }).then((res) => {
        if(res.data) {
          localStorage.setItem("tokenVV", res.data.token);
          localStorage.setItem(
            "userInfo",
            JSON.stringify(res.data.user)
          );
        }
      });
    },
    getData() {
      this.$request({
        url: this.$api.home,
        params: {
          activityId: this.id,
        },
      }).then((res) => {
        this.details = res.item;
        localStorage.setItem("code", this.details.code);
        localStorage.setItem("name", this.details.name);
        localStorage.setItem("id", this.id);
        this.content = decodeURI(res.item.introduce);
        this.details.attachViews.forEach((item) => {
          this.imgList.push(this.resourceUrl + item.path);
        });
      });
    },
    prev(key) {
      ImagePreview({
        images: this.imgList,
        startPosition: key,
      });
    },
    goPath() {
      // window.open(`${this.$api.root}/Activity?id=${this.id}`, "_self")
      this.$router.push(`/Activity?id=${this.id}`)
    },
  }
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
  // background-color: #f8f9fc;
  // overflow: auto;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   width: 100%;
  //   height: 100;
  // }

  .header {
    padding: 35px 0 25px 0;
    color: #fff;
    text-align: center;
    div:nth-of-type(1) {
      font-size: 20px;
      margin-bottom: 6px;
    }
    div:nth-of-type(2) {
      font-size: 14px;
    }
  }

  .my-swipe {
    width: 345px;
    height: 172px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0px 20px 40px -15px rgba(27, 136, 237, 0.2);

    img {
      width: 100%;
      max-width: 100%;
    }
  }

  .content-line {
    display: flex;
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 30px;
    color: #3e4576;
    div:nth-of-type(1) {
      min-width: 30%;
      color: #8b8fad;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .caption-title {
    color: #3e4576;
    font-size: 17px;
    position: relative;
    margin-left: 17px;
    margin-bottom: 16px;
    &::before {
      content: "";
      width: 3px;
      height: 50%;
      background: #3e4576;
      position: absolute;
      left: -10px;
      top: 5px;
      border-radius: 5px;
    }
  }

  .details {
    line-height: 1.5;
    img {
      max-width: 100%;
    }
  }

  .zw {
    height: 44px;
  }

  .bottom-nav {
    width: 100%;
    position: fixed;
    bottom: 0;
    display: flex;
    background: #fff;
    height: 44px;
    color: #3e4576;
    font-size: 14px;

    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .active {
      color: #fff;
      background: #3979ff;
    }
  }
}
</style>
