import axios from 'axios'
import qs from 'qs'
import api from '@/utils/api'
import {getTokenVV} from '@/utils/localStorage'
import store from '@/store'
import router from '@/router'
import { Toast } from 'vant';

const request = axios.create({
    baseURL: api.vvapi,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
})

request.interceptors.request.use(config => {
    if (getTokenVV()) { 
        config.headers['token'] = getTokenVV()
    }
    if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
        config.data = qs.stringify(config.data)
        // config.data = qs.stringify(config.data, {arrayFormat: 'repeat'})
    }
    return config
}, error => {
    return Promise.reject(error)
})

// 添加响应拦截器
request.interceptors.response.use(function (response) {
    const {status, data} = response
    if (status >= 200 && status < 300) {
        if (data.success) {
            return response.data
        } else if (data.msg.indexOf('TOKEN不存在') >= 0) {
            localStorage.removeItem('tokenVV')
            let id = localStorage.getItem('id') || ''
            router.replace('/login?id='+id).catch(err => { console.log(err) })

            return Promise.reject(response)
        } else if (data.msg === '请求成功') {
            // Toast(data.msg)
            return response.data
        } else {
            
            return Promise.reject(response)
        }
    }
    // 对响应数据做点什么
    return response;
}, function (error) {
    switch (error.response.status) {
        case 500:
            Toast('登录状态已过期，请重新登录')
            let id = localStorage.getItem('id') || ''
            router.replace('/login?id='+id).catch(err => { console.log(err) })
            break;
        //     Notification.error(error.response.status + "")
        //     break;
    }
    // 对响应错误做点什么
    return Promise.reject(error);
});


export default request
