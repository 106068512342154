<template>
  <div class="activity custom-bg" v-if="details">
    <div class="header">
      <div>{{ details.name }}</div>
      <div>
        {{ details.startTime | formatDate }} -
        {{ details.endTime | formatDate }}
      </div>
    </div>

    <div class="custom-container mr">
      <div class="activify-info">
        <div class="info">
          <div>{{ nowDate }} {{ nowWeek }}</div>
          <div v-if="isEnd">活动已经结束了<span></span></div>
          <div v-else>
            {{ daysTxt }}<span>{{ days }}</span
            >天
          </div>
          <div>
            <span>组织时间</span
            ><span
              >{{ details.organizationStartTime | formatDate }} ~
              {{ details.organizationEndTime | formatDate }}</span
            >
          </div>
          <div>
            <span>活动地点</span><span>{{ details.address }}</span>
          </div>
        </div>
        <!-- <div class="weather" v-if="weather">
          <div>{{ city }}·{{ area }}</div>
          <div style="font-size: 32px">{{ weather.wendu }}°</div>
          <div>{{ weather.type }}</div>
          <div>{{ weather.low }}° ~ {{ weather.high }}°</div>
        </div> -->
      </div>

      <div class="bottom-nav">
        <div @click="unit = true">组织单位</div>
        <div @click="admin = true">活动总指挥</div>
        <div @click="assistant = true">活动助理</div>
      </div>
    </div>

    <div class="header-tip">首日为签到日</div>

    <div class="custom-container mr10">
      <div class="fsbc">
        <div class="caption-title-cir c2">参与信息</div>
        <div class="msg-entry" @click="getMsg">活动通知</div>
      </div>
      <div class="fsbc people-info">
        <div>参与时间</div>
        <div>
          {{ details.peopleItemView.participationStartTime | formatDate }} ~
          {{ details.peopleItemView.participationEndTime | formatDate }}
        </div>
      </div>
      <div class="fsbc people-info">
        <div>{{ details.peopleItemView.personnelPost | formatPosition }}</div>
        <div>{{ details.peopleItemView.name }}</div>
      </div>
      <div class="fsbc people-info" v-if="details.vehicleView">
        <div>{{ details.vehicleView.vehicleBrand }}</div>
        <div>{{ details.vehicleView.modelName }}</div>
      </div>
      <!-- <div v-if="isShowMsg" style="white-space: pre-line;line-height: 1.5;">{{msg}}</div> -->
      <!-- <div v-if="isShowMsg" class="resource-details" v-html="decodeURI(msg)"></div> -->
    </div>

    <div
      class="custom-container mr10"
      v-if="
        details.peopleItemView.configureViews[0].configureId &&
        (details.sign || details.signOut)
      "
    >
      <div class="fsbc">
        <div class="caption-title-cir c1">活动参与</div>
        <div class="f12">
          <template v-if="sign.startTime">{{
            sign.startTime | formatDate
          }}</template>
          <template v-if="sign.startTime && sign.endTime"> ~ </template>
          <template v-if="sign.endTime">{{
            sign.endTime | formatDate
          }}</template>
        </div>
      </div>
      <div class="fsbc" style="margin-top: 10px">
        <div
          class="box1"
          @click="goSign('/signin', 0)"
          v-if="
            details.peopleItemView.configureViews[0].configureId && details.sign
          "
        >
          <div v-if="details.peopleItemView.signMark">
            {{ details.peopleItemView.confirmTime | formatTime }}
          </div>
          <div v-else>{{ sign.startTime | formatDate }}</div>
          <div v-if="!details.peopleItemView.signMark">需签到</div>
          <div v-else>
            已签到<img src="@/assets/images/success.png" alt="" />
          </div>
        </div>
        <div
          class="box1"
          @click="goSign('/signin', 1)"
          v-if="
            details.peopleItemView.configureViews[6].configureId &&
            details.signOut
          "
        >
          <div v-if="details.peopleItemView.signOutMark">
            {{ details.peopleItemView.signOutTime | formatTime }}
          </div>
          <div v-else>{{ sign.endTime | formatDate }}</div>
          <div v-if="!details.peopleItemView.signOutMark">需签退</div>
          <div v-else>
            已签退<img src="@/assets/images/success.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div
      class="custom-container mr10"
      v-if="
        details.peopleItemView.configureViews[2].configureId &&
        details.accommodationArrange && 
        !(this.details && this.details.peopleItemView && this.details.peopleItemView.signOutMark)
      "
    >
      <div class="fsbc">
        <div class="caption-title-cir c2">活动住宿</div>
        <div class="f12">
          {{ accommodation.startTime | formatDate }} ~
          {{ accommodation.endTime | formatDate }}
        </div>
      </div>
      <div class="fsbb" style="margin-top: 10px">
        <div class="box2">
          <template v-if="details.peopleItemView.subsidyStatus === 1">
            <div>——</div>
          </template>
          <template v-else>
            <div>{{ details.peopleItemView.room || "" }}</div>
            <div
              v-if="
                details.peopleItemView.enterStatus === 1 &&
                details.peopleItemView.retreatStatus === 0
              "
            >
              {{ details.peopleItemView.enterTime | formatTime }}入住
            </div>
            <div v-else-if="details.peopleItemView.retreatStatus === 1">
              {{ details.peopleItemView.retreatTime | formatTime }}已退房
            </div>
            <div v-else>——</div>
          </template>
        </div>
        <div
          class="box3 fcc"
          v-if="details.peopleItemView.subsidyStatus === 1"
          @click="goSign('/rest', 3)"
        >
          已选择补贴
        </div>
        <div
          class="box3 fcc active"
          v-else-if="details.peopleItemView.enterStatus === 0"
          @click="goSign('/rest', 0)"
        >
          办理入住
        </div>
        <div
          class="box3 fcc"
          v-else-if="details.peopleItemView.retreatStatus === 0"
          @click="goSign('/rest', 1)"
        >
          办理退房
        </div>
        <div
          class="box3 fcc active"
          v-else-if="details.peopleItemView.retreatStatus === 1 && isInDate"
          @click="goSign('/rest', 2)"
        >
          办理续住
        </div>
      </div>
    </div>

    <div
      class="custom-container mr10"
      v-if="
        details.peopleItemView.configureViews[3].configureId &&
        details.restaurantArrange &&
        !(this.details && this.details.peopleItemView && this.details.peopleItemView.signOutMark)
      "
    >
      <div class="fsbc">
        <div class="caption-title-cir c3">活动餐饮</div>
        <div class="f12">
          {{ restaurant.startTime | formatDate }}-{{
            restaurant.endTime | formatDate
          }}
        </div>
      </div>
      <div class="box4" style="margin-top: 10px">
        <div @click="goSign('/food', 0)">早餐</div>
        <div @click="goSign('/food', 1)">午餐</div>
        <div @click="goSign('/food', 2)">晚餐</div>
      </div>
    </div>

    <div class="custom-container mr10">
      <div class="fsbc">
        <div class="caption-title-cir c4">活动其它</div>
        <div class="f12">
          {{ details.organizationStartTime | formatDate }} ~
          {{ details.organizationEndTime | formatDate }}
        </div>
      </div>
      <div class="fsbc box5" style="margin-top: 10px">
        <div
          @click="goPath('/goods')"
          v-if="isShowGoods"
        >
          活动物资
        </div>
        <div
          @click="goSign('/service', 0)"
          v-if="isShowService"
        >
          活动服务
        </div>
        <div
          @click="
            openUrl(fmsLink)
          "
        >
          费用登记
        </div>
        <div
          @click="
            openUrl(
              'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxf8af826931365ee0&redirect_uri=https%3A%2F%2Fvip.vv-che.com%2Fregister%2F%23%2FReportMission&response_type=code&scope=snsapi_base&state=ReportMission#wechat_redirect'
            )
          "
        >
          任务上报
        </div>
      </div>
    </div>

    <van-popup
      v-model="unit"
      round
      position="bottom"
      class="popup-unit"
      safe-area-inset-bottom
    >
      <div class="unit-top">
        <img :src="company.logo" alt="" />
        <div>
          <div class="unit-name">
            <div>{{ company.shortname }}</div>
            <div class="auth" v-if="company.status === 2">
              <img src="@/assets/images/auth.png" alt="" />已认证
            </div>
          </div>
          <div class="code">VV企业码：{{ company.serialNo }}</div>
        </div>
      </div>
      <div class="unit-info">
        <div class="info-line">
          <div>联络人</div>
          <div>
            {{ superadmin }}
            <a class="phone" :href="'tel:' + superadminPhone" ref="telPhone">{{
              superadminPhone
            }}</a>
          </div>
        </div>
        <div class="info-line">
          <div>邮箱</div>
          <div>{{ company.email }}</div>
        </div>
        <div class="info-line">
          <div>官网</div>
          <div>{{ company.clink }}</div>
        </div>
        <div class="info-line">
          <div>地址</div>
          <div>{{ company.workAddress }}</div>
        </div>
      </div>
      <div class="unit-qr">
        <div class="qr-item">
          <img :src="company.qrcode" alt="" />
          <div>企业信息码</div>
        </div>
        <div class="qr-item">
          <img :src="company.qrcode" alt="" />
          <div>企业开票码</div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="admin"
      round
      position="bottom"
      class="popup-admin"
      safe-area-inset-bottom
    >
      <div class="admin-top">活动总指挥</div>
      <div class="admin-info">
        <div class="info-line">
          <div>姓名</div>
          <div>{{ commander.name }}</div>
        </div>
        <div class="info-line">
          <div>电话</div>
          <div>
            <a class="phone" :href="'tel:' + commander.mobile" ref="telPhone">{{
              commander.mobile
            }}</a>
          </div>
        </div>
        <div class="info-line">
          <div>微信号</div>
          <div>
            {{ commander.weiXin || "——" }}
            <div
              class="copy"
              v-if="commander.weiXin"
              :data-clipboard-text="commander.weiXin"
              @click="handleCopy"
            >
              复制 <img src="@/assets/images/copy.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="assistant"
      round
      position="bottom"
      class="popup-admin"
      safe-area-inset-bottom
    >
      <div class="admin-top">活动助理</div>
      <van-search
        v-model.trim="assistantName"
        @search="onSearch"
        @clear="onSearch"
        placeholder="搜索助理姓名"
        shape="round"
      />
      <div
        class="admin-info"
        v-for="(item, index) in assistantList"
        :key="index"
      >
        <div class="info-line">
          <div>姓名</div>
          <div>{{ item.name }}</div>
        </div>
        <div class="info-line">
          <div>电话</div>
          <div>
            <a class="phone" :href="'tel:' + item.mobile" ref="telPhone">{{
              item.mobile
            }}</a>
          </div>
        </div>
        <div class="info-line">
          <div>微信号</div>
          <div>
            {{ item.assistantWeiXin || "——" }}
            <div
              class="copy"
              v-if="item.assistantWeiXin"
              :data-clipboard-text="item.assistantWeiXin"
              @click="handleCopy"
            >
              复制 <img src="@/assets/images/copy.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="isShowMsg"
      round
      position="bottom"
      class="custom-popup"
      style="height:60vh"
      safe-area-inset-bottom
    >
      <div class="admin-info">
        <div class="caption-title">活动通知列表</div>
        <div class="msg-item" v-for="(item,index) in msgList" :key="index" @click="getMsgDetail(item.id)" style="padding:10px 0;color:#3979ff">{{item.title}}</div>
      </div>
    </van-popup>

    <van-popup
      v-model="msgDetailVisible"
      round
      position="bottom"
      class="custom-popup"
      style="height:60vh"
      safe-area-inset-bottom
    >
      <div class="admin-info">
        <div class="caption-title">{{msgTitle}}</div>
        <div class="resource-details" v-html="decodeURI(msg)"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Clipboard from "clipboard";

export default {
  data() {
    return {
      unit: false,
      admin: false,
      assistant: false,
      searchKey: "",

      id: "", // 活动id
      details: null, // 活动详情
      city: "",
      area: "",
      weather: {
        wendu: "",
        type: "",
        high: "",
        low: "",
      }, // 天气信息
      // 日期计算
      dateInfo: {
        date: "",
        week: "",
        days: "",
      },
      nowDateSign: "",
      company: {}, // 组织单位
      superadmin: "",
      superadminPhone: "",
      commander: {}, // 总指挥
      assistantList: [], // 助理列表
      assistantName: "", // 助理搜索
      sign: {}, // 签到签退
      accommodation: {}, // 活动住宿
      restaurant: {}, // 活动餐饮
      other: {}, // 其他
      msg: "", // 活动通知
      msgTitle: '',
      msgList: [],
      msgDetailVisible: false,

      // 日期
      nowDate: "",
      nowWeek: "",
      isEnd: false,
      daysTxt: "",
      days: "",
      isInDate: false,
      isShowMsg: false,
      fmsLink: 'http://financeh5.vv-che.com/pages/feeRegister/feeRegister?t='
    };
  },
  computed: {
    isShowGoods() {
      let res = false;
      // 签退后隐藏入口
      if(this.details && this.details.peopleItemView && this.details.peopleItemView.signOutMark) {
        return false
      }

      if (this.details && this.details.peopleItemView) {
        if (
          this.details.peopleItemView.configureViews[1].configureId ||
          this.details.peopleItemView.configureViews[4].configureId
        ) {
          res = true;
        }
      }
      if (this.details && this.details.vehicleView) {
        if (
          this.details.vehicleView.configureViews[1].configureId ||
          this.details.vehicleView.configureViews[2].configureId
        ) {
          res = true;
        }
      }
      return res;
    },
    isShowService() {
      let res = false;

      // 签退后隐藏入口
      if(this.details && this.details.peopleItemView && this.details.peopleItemView.signOutMark) {
        return false
      }

      if (this.details && this.details.peopleItemView) {
        if (this.details.peopleItemView.configureViews[5].configureId) {
          res = true;
        }
      }
      if (this.details && this.details.vehicleView) {
        if (
          this.details.vehicleView.configureViews[3].configureId != "0" ||
          this.details.vehicleView.configureViews[4].configureId != "0"
        ) {
          res = true;
        }
      }
      return res;
    },
  },
  created() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      localStorage.setItem("id", this.id);
      this.getData();
      this.getCompany();
      this.getCommander();
      this.getAssistant();
      // navigator.geolocation.getCurrentPosition(this.onSuccess, this.onError);
    }

    this.fmsLink = `${this.fmsLink}${localStorage.getItem("tokenVV")}`
  },
  methods: {
    openUrl(url) {
      window.open(url);
    },
    onSuccess(pos) {
      this.getCity(pos.coords.latitude, pos.coords.longitude);
    },
    onError(err) {
      this.weather = null;
      // this.$toast('您已拒绝授权定位信息')
    },
    getCity(longitude, latitude) {
      this.$request({
        url: this.$api.getCity,
        params: {
          longitude: longitude,
          latitude: latitude,
        },
      }).then((res) => {
        if (res.item) {
          let temp = res.item.split("·");
          this.city = temp[0].trim();
          this.area = temp[1].trim();
          this.getWeather(this.city);
        }
      });
    },
    // 获取活动详情
    getData() {
      this.$request({
        url: this.$api.activityDetails,
        params: {
          activityId: this.id,
        },
      }).then((res) => {
        this.details = res.item;
        localStorage.setItem("id", this.details.id);
        localStorage.setItem("name", this.details.name);

        this.handleDate(this.details.startTime, this.details.endTime);
        this.sign.startTime =
          this.details.peopleItemView.configureViews[0].startTime;
        this.sign.endTime =
          this.details.peopleItemView.configureViews[6].endTime;
        this.accommodation.startTime =
          this.details.peopleItemView.configureViews[2].startTime;
        this.accommodation.endTime =
          this.details.peopleItemView.configureViews[2].endTime;
        this.restaurant.startTime =
          this.details.peopleItemView.configureViews[3].startTime;
        this.restaurant.endTime =
          this.details.peopleItemView.configureViews[3].endTime;

        // 是否在续住时间内
        let now = this.$moment();
        let end = this.$moment(`${this.accommodation.endTime} 12:00:00`);
        this.isInDate = now < end;
      });
    },
    // 获取活动通知
    getMsg() {
      this.isShowMsg = true
      this.$request({
        url: this.$api.getMsg,
        params: {
          activityId: this.id,
        },
      }).then((res) => {
        this.msgList = res.items || []
        // this.msg = (res.item && res.item.message) || "";
      });
    },
    // 获取活动通知详情
    getMsgDetail(id) {
      this.$request({
        url: this.$api.detailMessage,
        params: {
          messageId : id,
        },
      }).then((res) => {
        if(res.item) {
          this.msgDetailVisible = true
          this.msgTitle = res.item.title
          this.msg = res.item.message || ''
        }
      });
    },
    // 获取活动总指挥
    getCommander() {
      this.$request({
        url: this.$api.activityCommander,
        method: "POST",
        data: {
          activityId: this.id,
        },
      }).then((res) => {
        this.commander = res.item || {};
      });
    },
    // 获取活动助理
    getAssistant() {
      this.$request({
        url: this.$api.activityAssistant,
        method: "POST",
        data: {
          activityId: this.id,
          assistant: this.assistantName,
        },
      }).then((res) => {
        this.assistantList = res.items || [];
      });
    },
    // 获取组织单位
    getCompany() {
      this.$vv({
        url: this.$api.company,
        params: {
          companyId: localStorage.getItem("companyId"),
        },
      }).then((res) => {
        this.company = res.data;

        let arr = this.company.superadmin.split(" ");
        this.superadmin = arr[0];
        this.superadminPhone = arr[1];
      });
    },
    // 获取天气信息
    getWeather(city) {
      this.$vv({
        url: this.$api.weather,
        params: {
          city: city,
        },
      })
        .then((res) => {
          let info = res.data.data;
          this.weather.wendu = info.wendu;
          this.weather.type = info.forecast[0].type;
          let highTemp = info.forecast[0].high.split(" ")[1];
          this.weather.high = highTemp.substr(0, highTemp.length - 1);
          let lowTemp = info.forecast[0].low.split(" ")[1];
          this.weather.low = lowTemp.substr(0, lowTemp.length - 1);
        })
        .catch((err) => {});
    },
    handleDate(start, end) {
      let now = this.$moment().format("l");
      let startDate = this.$moment(start).format("l");
      let endDate = this.$moment(end).format("l");
      this.nowDate = this.$moment().format("ll");
      this.nowWeek = this.$moment().format("dddd");

      if (this.$moment() > this.$moment(end)) {
        this.isEnd = true;
        return;
      }
      if (this.$moment() < this.$moment(start)) {
        let days = this.$moment(start).diff(this.$moment().format("l"), "days");
        this.daysTxt = "距离活动开始";
        this.days = days;
      } else {
        let days = this.$moment(end).diff(this.$moment().format("l"), "days");
        this.daysTxt = "距离活动结束";
        this.days = days;
      }
    },

    // 通过选择的日期获取星期和距离活动天数
    handleDateInfo(startTime) {
      let now = this.getDate();
      let arr = now.split("-");
      this.dateInfo.date = `${arr[0]}年${arr[1]}月${arr[2]}日`;

      let day = new Date(now).getDay();
      let dayStr = "";
      switch (day) {
        case 1:
          dayStr = "一";
          break;
        case 2:
          dayStr = "二";
          break;
        case 3:
          dayStr = "三";
          break;
        case 4:
          dayStr = "四";
          break;
        case 5:
          dayStr = "五";
          break;
        case 6:
          dayStr = "六";
          break;
        case 0:
          dayStr = "日";
          break;
      }
      this.dateInfo.week = `星期${dayStr}`;

      let t1 = new Date(startTime);
      let t2 = new Date(now);
      if (t2 > t1) {
        this.dateInfo.days = 0;
      } else {
        this.dateInfo.days = Math.ceil(
          Math.abs(new Date(t1) - new Date(t2)) / 86400000
        );
      }
    },
    // 获取当前日期
    getDate() {
      let d = new Date();
      let ye = d.getFullYear();
      let mo = (d.getMonth() + 1).toString().padStart(2, "0");
      let da = d.getDate().toString().padStart(2, "0");
      let time = ye + "-" + mo + "-" + da;
      this.nowDateSign = ye + "/" + mo + "/" + da;
      return time;
    },
    // 搜索助理
    onSearch() {
      this.getAssistant();
    },
    // 复制
    handleCopy() {
      let clipboard = new Clipboard(".copy");
      clipboard.on("success", (e) => {
        this.$toast.success("复制成功");
        clipboard.destroy(); // 释放内存
      });
      clipboard.on("error", (e) => {
        this.$toast.fail("复制失败");
        clipboard.destroy(); // 释放内存
      });
    },
    goSign(path, type) {
      // if(!this.details.peopleItemView.signOutMark) {
      //   this.$toast('已经完成签退啦')
      //   return
      // }
      this.$router.push(`${path}?type=${type}`);
    },
    goPath(path) {
      // if(this.details.peopleItemView.signOutMark) {
      //   this.$toast('已经完成签退啦')
      //   return
      // }
      this.$router.push(path);
    },
  },
};
</script>

<style lang="scss" scoped>
$data-attr: (
  "c1": #fea701,
  "c2": #42b983,
  "c3": #ff4a41,
  "c4": #8b8fad,
);
.activity {
  min-height: 100vh;
  // background-color: #f8f9fc;
  // overflow: auto;
  .header {
    padding: 30px 0;
    color: #fff;
    text-align: center;
    div:nth-of-type(1) {
      font-size: 20px;
      margin-bottom: 6px;
    }
    div:nth-of-type(2) {
      font-size: 14px;
    }
  }

  .mr {
    position: relative;
    z-index: 99;
    margin-top: 0;
    margin-bottom: 0;
  }

  .mr10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .people-info {
    font-size: 16px;
    line-height: 16px;
    color: #3e4576;
    margin: 12px 0;
  }

  .activify-info {
    display: flex;

    .info {
      flex: 1;
      font-size: 16px;
      div:nth-of-type(1) {
        color: #8b8fad;
      }
      div:nth-of-type(2) {
        color: #3e4576;
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 14px;
        span {
          font-size: 36px;
        }
      }
      div:nth-of-type(3),
      div:nth-of-type(4) {
        color: #3e4576;
        margin-top: 10px;
        span:nth-of-type(1) {
          color: #8b8fad;
          padding-right: 10px;
        }
      }
    }
    .weather {
      border-left: 1px solid #f3f4ff;
      width: 90px;
      font-size: 12px;
      padding-left: 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      div:nth-of-type(odd) {
        color: #8b8fad;
      }
      div:nth-of-type(even) {
        color: #3e4576;
      }
    }
  }

  .bottom-nav {
    display: flex;
    justify-content: space-between;
    color: #3979ff;
    font-size: 14px;
    margin-top: 22px;
  }

  .caption-title-cir {
    color: #3e4576;
    font-size: 14px;
    position: relative;
    margin-left: 12px;
    &::before {
      content: "";
      width: 6px;
      height: 6px;
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 5px;
    }
    @each $name, $glyph in $data-attr {
      &.#{$name} {
        &::before {
          background: $glyph;
        }
      }
    }
  }

  .box1 {
    width: 148px;
    height: 72px;
    background: rgba(112, 182, 3, 0.1);
    border-radius: 16px;
    padding: 12px 14px;
    box-sizing: border-box;
    text-align: center;
    font-size: 14px;
    color: #70b603;

    &:nth-of-type(2) {
      background: rgba(245, 154, 35, 0.1);
      color: #f59a23;
    }

    div:nth-of-type(2) {
      margin-top: 10px;
      position: relative;

      img {
        width: 14px;
        height: 14px;
        position: absolute;
        right: 10px;
        top: 2px;
      }
    }
  }

  .box2 {
    padding: 12px;
    box-sizing: border-box;
    width: 180px;
    min-height: 40px;
    border-radius: 16px;
    background: rgba(52, 118, 255, 0.1);
    color: #3476ff;
    font-size: 14px;
    text-align: center;
  }

  .box3 {
    width: 110px;
    height: 40px;
    background: #f6f9ff;
    box-shadow: 0px 20px 40px -18px rgba(27, 136, 237, 0.3);
    border-radius: 16px;
    font-size: 14px;
    color: #3e4576;
  }

  .active {
    background: #3476ff;
    color: #fff;
  }

  .box4 {
    display: flex;
    font-size: 14px;
    div {
      height: 40px;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    div:nth-of-type(1) {
      background: #ffecec;
      color: #ff4a41;
      border-radius: 16px 0px 0px 16px;
    }
    div:nth-of-type(2) {
      background: #fff5d8;
      color: #fda703;
    }
    div:nth-of-type(3) {
      background: #ecf8f2;
      color: #42b983;
      border-radius: 0px 16px 16px 0px;
    }
  }

  .box5 {
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      width: 69px;
      height: 40px;
      background: #f8fafe;
      border-radius: 16px;
    }
  }

  .popup-unit {
    padding: 20px 25px 10px 25px;
    box-sizing: border-box;
    .unit-top {
      display: flex;
      > img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
      > div {
        display: flex;
        flex-direction: column;
        color: #3e4576;
        margin-left: 20px;
        .unit-name {
          display: flex;
          align-items: center;
          div:nth-of-type(1) {
            font-size: 17px;
            margin-right: 10px;
          }

          .auth {
            width: 82px;
            height: 22px;
            background: #3476ff;
            border-radius: 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            color: #fff;
            img {
              width: 16px;
              height: 16px;
              margin-right: 2px;
            }
          }
        }
        .code {
          margin-top: 10px;
          font-size: 16px;
        }
      }
    }
    .unit-info {
      margin: 20px 0;
      .info-line {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #3e4576;
        margin-bottom: 22px;
        div:nth-of-type(1) {
          font-weight: bold;
          width: 44px;
        }
        div:nth-of-type(2) {
          flex: 1;
          text-align: right;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .unit-qr {
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      .qr-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        color: #000;
        img {
          width: 90px;
          height: 90px;
          margin-bottom: 4px;
        }
      }
    }
  }

  .popup-admin {
    padding: 20px 25px 18px 25px;
    box-sizing: border-box;
    max-height: 70%;
    .admin-top {
      color: #3e4576;
      font-size: 17px;
      font-weight: bold;
      text-align: center;
      margin-bottom: 20px;
    }
    .admin-info {
      margin-bottom: 30px;
      .info-line {
        display: flex;
        font-size: 16px;
        margin-bottom: 30px;
        > div:nth-of-type(1) {
          width: 80px;
          color: #8b8fad;
        }
        > div:nth-of-type(2) {
          flex: 1;
          color: #3e4576;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .copy {
            display: flex;
            align-items: center;
            color: #3476ff;
            img {
              width: 16px;
              height: 16px;
              margin-left: 6px;
            }
          }
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .van-search {
    padding: 0 0 20px 0;
  }
  .van-search__content {
    background: #f6f9ff;
  }

  .header-tip {
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    color: #3e4576;
    margin: 10px 0;
  }
}

.msg-entry {
  color:#3979ff;
  font-size: 14px;
}
</style>
